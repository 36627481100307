import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import NetlifyCms from '../components/NetlifyCms';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookieConsent from '../components/CookieConsent';

import '../styles/main.sass';

const Layout = ({ data, children, location }) => {
  const { siteMetadata: meta } = data.site;
  const { frontmatter: contact } = data.markdownRemark;

  return (
    <div className="container">
      <Helmet
        title={meta.title}
        meta={[
          { name: 'description', content: meta.description },
          { name: 'keywords', content: meta.keywords.join(', ') },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
          },
        ]}
      />

      <Header siteMeta={meta} location={location} />

      {children}

      <CookieConsent />
      <NetlifyCms />
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        markdownRemark(frontmatter: { urlPath: { eq: "/kontakt" } }) {
          frontmatter {
            phone
            email
          }
        }
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);
