import React, { Component } from 'react';
import Cookies from 'js-cookie';

const COOKIE_NAME = 'eu_cookies_consented';

export default class CookieConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: !Cookies.get(COOKIE_NAME),
    };

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    this.setState({ show: false });
    Cookies.set(COOKIE_NAME, true);
  }

  render() {
    if (!this.state.show) return false;

    return (
      <div className="cookieConsent">
        <div className="cookieConsent__text">
          Pro zlepšení uživatelského komfortu uživatelů našich internetových
          stránek a pro zajištění dalších úkolů požíváme cookies. Používáním s
          tímto souhlasíte.
        </div>

        <a
          href="#"
          className="cookieConsent__ok btn btn-default"
          onClick={this.handleConfirm}
        >
          OK
        </a>
      </div>
    );
  }
}
