import React from 'react';
import remark from 'remark';
import remarkHtml from 'remark-html';

const md2html = content =>
  remark()
    .use(remarkHtml)
    .processSync(content)
    .toString();

export default ({ content, className }) => (
  <div className={className}>{content}</div>
);

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

export const MarkdownContent = ({ content, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: md2html(content) }}
  />
);
