import React from 'react';
import Link from 'gatsby-link';
import classnames from 'classnames';

import logo from '../img/logo.png';

const NavItem = ({ name, highlight, link, location }) => (
  <li
    className={classnames({
      'nav-item': true,
      active: location.pathname === link,
      highlighted: highlight,
    })}
  >
    <Link to={link} className="nav-link">
      {name}
    </Link>
  </li>
);

const Header = ({ siteMeta, location }) => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light row header">
    <Link className="navbar-brand" to="/">
      <img src={logo} alt={siteMeta.title} width="200px" />
    </Link>

    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#menu"
      aria-controls="menu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>

    <div className="collapse navbar-collapse justify-content-end" id="menu">
      <ul className="navbar-nav">
        <NavItem link="/pokoje" name="Pokoje" location={location} />
        <NavItem
          link="/trebon-a-okoli"
          name="Kam na výlet"
          location={location}
        />
        <NavItem link="/kontakt" name="Kontakt" location={location} />
      </ul>
    </div>
  </nav>
);

export default Header;
